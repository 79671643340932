import './styles/main.scss';
document.addEventListener('DOMContentLoaded', () => {
    let itemBurgerMenu = document.querySelectorAll(".burger__item");
    let burgerMenu = document.querySelector(".header__nav");
    let burger = document.querySelector(".header__burger");
    let burgerBg = document.querySelector(".burger-bg");
    let img = document.getElementsByTagName('img');
    let scroll = calcScroll();
    // console.log(burger);
    // console.log(burgerMenu);
    // console.log(itemBurgerMenu)


    window.onscroll = function() { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector("header").classList.add("active");
        } else {
            document.querySelector("header").classList.remove("active");
        }
    }
    burger.addEventListener("click", () => {
        burger.classList.toggle("active");
        burgerMenu.classList.toggle("active");
        burgerBg.classList.toggle("active");
        document.body.classList.toggle("active");
        document.body.style.marginRight = `${scroll}px`;
    })
    document.addEventListener("click", function(event) {
        if (event.target == burgerBg) {
            burger.classList.toggle("active");
            burgerMenu.classList.toggle("active");
            burgerBg.classList.toggle("active");
            document.body.classList.toggle("active");
            document.body.style.marginRight = ``;
        }
    });

    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector("header").classList.add("active");
        } else {
            document.querySelector("header").classList.remove("active");
        }
    }

    function calcScroll() {
        let div = document.createElement("div");
        div.style.width = "50px";
        div.style.height = "50px";
        div.style.overflowY = "scroll";
        div.style.visibility = "hidden";

        document.body.appendChild(div);
        let scrollWidth = div.offsetWidth - div.clientWidth;
        div.remove();
        return scrollWidth;
    }


    for (let i in img) {
        img[i].oncontextmenu = function() {
            return false;
        }
    }
})